module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://go.brightspeed.com"},
    },{
      plugin: require('../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-KKGCP7D7","brandTokens":["BSP"],"mapiBrandToken":"BSP","siteName":"go.brightspeed","alternateName":"go.brightspeed.com","siteURL":"https://go.brightspeed.com","defaultTitleTemplate":"","defaultPhone":"8339644279","phoneSymbol":"-","defaultPromoCode":"162952","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046133","vwoProjectID":"894940","datasetManagerAPIKey":"f18ad9a3c7ba4cb0b4b548ec2c49c30b","cobra":{"sitePath":"go.brightspeed.com","buttonLink":"/cart","buttonText":"Order Now","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"go.brightspeed","short_name":"go.brightspeed","start_url":"/","background_color":"#FFC800","theme_color":"#FFC800","icon":"src/images/favicon.svg","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8cc960dfc339fc1e180ac68d2244ebd4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
